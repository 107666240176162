import * as React from 'react'
import styled from 'styled-components'
import FontDefinition from './components/FontDefinition'
import MainContainer from './components/MainContainer'
import TemplatePageStyle from './components/TemplatePageStyle'

type BasicLayoutProps = {
  children: React.ReactNode
}

const CentredMainContainer = styled(MainContainer)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  min-height: 100vh;
`

const DatalessLayout = function DatalessLayout({ children }: BasicLayoutProps) {
  return (
    <>
      <FontDefinition />
      <TemplatePageStyle />
      <CentredMainContainer>{children}</CentredMainContainer>
    </>
  )
}

export default DatalessLayout
