import { colours, Title } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'
import BasicPageContainer from '../../components/BasicPageContainer'
import Layout, { LayoutData } from '../../components/Layout'
import DatalessLayout from '../../components/Layout/DataLessLayout'
import RouterLinkButton from '../../components/RouterLinkButton'
import Seo from '../../components/Seo'

type NotFoundPageProps = {
  pageContext?: {
    layoutData?: LayoutData
  }
}

const ErrorNumber = styled.strong`
  display: block;
  font-size: 2rem;
  font-weight: bold;
  color: ${colours.GREY};
  margin-bottom: 10px;
  text-align: center;
`

const Message = styled.p`
  font-size: 13px;
  font-weight: 500;
  text-align: center;
`

const HeaderBlock = styled.header`
  margin-bottom: 2em;
`

const NotFound = ({
  pageContext: { layoutData = null } = {},
}: NotFoundPageProps) => {
  const content = (
    <>
      <Seo title="404" type="website" />
      <BasicPageContainer>
        <HeaderBlock>
          <ErrorNumber>404</ErrorNumber>
          <Title align="center" size="big">
            Page unavailable
          </Title>
        </HeaderBlock>
        <Message>It looks like you&#39;re lost.</Message>
        <RouterLinkButton to="/">Take me home</RouterLinkButton>
      </BasicPageContainer>
    </>
  )
  return layoutData ? (
    <Layout data={layoutData}>{content}</Layout>
  ) : (
    <DatalessLayout>{content}</DatalessLayout>
  )
}

export default NotFound
